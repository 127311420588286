window.addEventListener("load", () => {
  const applyPaymentMode = (mode) => {
    const bankElement =
      document.querySelector("div.account_bank_name") ||
      document.querySelector("div.subscription_bank_name");
    const chequeNumberElement =
      document.querySelector("div.account_cheque_number") ||
      document.querySelector("div.subscription_cheque_number");
    if (mode === "cheque") {
      bankElement.classList.remove("hidden");
      chequeNumberElement.classList.remove("hidden");
    } else {
      bankElement.classList.add("hidden");
      chequeNumberElement.classList.add("hidden");
    }
  };
  const selectElement =
    document.querySelector("#account_payment_mode") ||
    document.querySelector("#subscription_payment_mode");
  if (selectElement) {
    applyPaymentMode(selectElement.value);
    selectElement.addEventListener("change", (elem) => {
      applyPaymentMode(event.target.value);
    });
  }

  const switchSubscriptionMode = (mode) => {
    const bulkSubscriptionElement =
      document.querySelector(".bulk-subscription");
    const razorpaySubscriptionElement = document.querySelector(
      ".razorpay-subscription"
    );
    if (mode) {
      bulkSubscriptionElement.classList.add("hidden");
      razorpaySubscriptionElement.classList.remove("hidden");
      document.querySelector("#account_subscription_plan_id").value = "";
      document.querySelector("#account_license_count").value = "";
      document.querySelector("#account_subscription_offer_price").value = "";
      document.querySelector("#account_subscription_setup_fee").value = "";
      document.querySelector("#account_payment_mode").value = "";
    } else {
      bulkSubscriptionElement.classList.remove("hidden");
      razorpaySubscriptionElement.classList.add("hidden");
      document.querySelector("#account_razorpay_plan_id").value = "";
    }
  };
  const selectSubscriptionModeElement = document.querySelector(
    "#subscription-switch"
  );
  if (selectSubscriptionModeElement) {
    switchSubscriptionMode(selectSubscriptionModeElement.checked);
    selectSubscriptionModeElement.addEventListener("change", (elem) => {
      switchSubscriptionMode(event.target.checked);
    });
  }

  const licenseTypeSelectorElementGetter = () =>
    document.querySelector("#account_subscription_plan_id") ||
    document.querySelector("#subscription_subscription_plan_id");
  const licenseCountValueElemGetter = () =>
    document.querySelector("#account_license_count") ||
    document.querySelector("#subscription_license_count");
  const priceOfferedElemGetter = () =>
    document.querySelector("#account_subscription_offer_price") ||
    document.querySelector("#subscription_subscription_offer_price");
  const advancePriceElemGetter = () =>
    document.querySelector("#account_subscription_setup_fee") ||
    document.querySelector("#subscription_subscription_setup_fee");

  const applyTotalPrice = () => {
    console.log("Need to apply total price");
    const licenseTypeSelectorElement = licenseTypeSelectorElementGetter();
    const licenseCountValueElem = licenseCountValueElemGetter();

    const plans = JSON.parse(
      document.querySelector(".subscription-plans-data").dataset.plans
    );
    const selectedPlan = plans.find(
      (item) => item.id === licenseTypeSelectorElement.value
    );
    if (selectedPlan) {
      const priceToShowElem = document.querySelector(
        ".total-subscription-price-incl-gst"
      );
      const numLicences = (licenseCountValueElem.value || 1) - 1;
      const basePrice = selectedPlan.pricing_details.base_price_units;
      const perUnitLicensePrice =
        selectedPlan.pricing_details.addon_pricing.find(
          (item) => item.key === "simulataneous-session-count"
        ).price_units;
      const totalPrice = basePrice + numLicences * perUnitLicensePrice;
      console.log(totalPrice);
      const totalSubscriptionPriceElem = document.querySelector(
        ".total-subscription-price-incl-gst"
      );
      totalSubscriptionPriceElem.innerText = (totalPrice / 100.0).toFixed(2);

      const priceOfferedElem = priceOfferedElemGetter();
      const offeredPrice = priceOfferedElem.value
        ? priceOfferedElem.value * 100
        : totalPrice;

      const discountOfferedElem = document.querySelector(".discount-offered");
      discountOfferedElem.innerText = (
        ((totalPrice - offeredPrice) * 100.0) /
        totalPrice
      ).toFixed(2);

      const advancePriceElem = advancePriceElemGetter();
      const finalAdvancePriceElem = document.querySelector(
        ".final-advance-price"
      );
      finalAdvancePriceElem.innerText = (
        (advancePriceElem.value || 0) / 1.18
      ).toFixed(2);

      const finalAdvanceGSTElem = document.querySelector(".final-advance-gst");
      finalAdvanceGSTElem.innerText = (
        (advancePriceElem.value || 0) *
        (0.18 / 1.18)
      ).toFixed(2);
    }
  };

  const licenseTypeSelectorElement = licenseTypeSelectorElementGetter();
  if (licenseTypeSelectorElement) {
    licenseTypeSelectorElement.addEventListener("change", (elem) => {
      applyTotalPrice();
    });
  }
  const licenseCountValueElem = licenseCountValueElemGetter();
  if (licenseCountValueElem) {
    licenseCountValueElem.addEventListener("input", (elem) => {
      applyTotalPrice();
    });
  }

  const priceOfferedElem = priceOfferedElemGetter();
  if (priceOfferedElem) {
    priceOfferedElem.addEventListener("input", (elem) => {
      applyTotalPrice();
    });
  }

  const advancePriceElem = advancePriceElemGetter();
  if (advancePriceElem) {
    advancePriceElem.addEventListener("input", (elem) => {
      applyTotalPrice();
    });
  }
});
